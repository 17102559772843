import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues } from 'react-hook-form';
import InputTextarea, { InputTextareaProps } from '@components/form-hook-input/input-textarea/InputTextarea';
import ComponentHorizontalWithLabel, {
  ComponentHorizontalWithLabelProps,
} from '@components/component-with-label/ComponentHorizontalWithLabel';

export interface InputTextareaFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputTextareaProps<TFieldValues, TName>,
    ComponentHorizontalWithLabelProps {}

const InputTextareaForm = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  tooltip,
  placeholder,
  tooltipWidth,
  className,
  ...props
}: InputTextareaFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);

  return (
    <ComponentHorizontalWithLabel labelTop className={className} label={label} tooltip={tooltip} tooltipWidth={tooltipWidth}>
      <InputTextarea placeholder={placeholder || t('please_enter', { label })} {...props} />
    </ComponentHorizontalWithLabel>
  );
};

export default InputTextareaForm;
