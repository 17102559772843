import React, { useImperativeHandle } from 'react';
import { FieldErrors, FieldValues, FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { preventFormSubmit } from '@helpers/@utils/prevent-form-submit';
import TriggerFormInitially from './TriggerFormInitially';

export type SubmitHandler<TFieldValues extends FieldValues = FieldValues, TContext = AnyValue> = (
  data: TFieldValues,
  event?: React.BaseSyntheticEvent,
  args?: UseFormReturn<TFieldValues, TContext>
) => AnyValue | Promise<AnyValue>;

export type SubmitErrorHandler<TFieldValues extends FieldValues = FieldValues, TContext = AnyValue> = (
  data: FieldErrors<TFieldValues>,
  event?: React.BaseSyntheticEvent,
  args?: UseFormReturn<TFieldValues, TContext>
) => AnyValue | Promise<AnyValue>;

interface FormHookWrapperProps<TFieldValues extends FieldValues = FieldValues, TContext = AnyValue>
  extends UseFormProps<TFieldValues, TContext> {
  validateInitially?: boolean;
  validationSchema?: AnyValue | (() => AnyValue);
  preventEnterSubmit?: boolean;
  onSubmit: SubmitHandler<TFieldValues, TContext>;
  onError?: SubmitErrorHandler<TFieldValues> | undefined;
  children?: (args: UseFormReturn<TFieldValues, TContext>) => React.ReactNode;
}

export type FormHookWrapperRef<TFieldValues extends FieldValues = FieldValues, TContext = AnyValue> = UseFormReturn<TFieldValues, TContext>;

function FormHookWrapperInner<TFieldValues extends FieldValues = FieldValues, TContext = AnyValue>(
  {
    children,
    onSubmit,
    onError,
    validateInitially,
    validationSchema,
    preventEnterSubmit = true,
    ...props
  }: FormHookWrapperProps<TFieldValues, TContext>,
  forwardRef: React.ForwardedRef<UseFormReturn<TFieldValues, TContext>>
): JSX.Element {
  const args = useForm<TFieldValues, TContext>({
    mode: 'all',
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    ...props,
  });

  const onFormSubmit = (data: TFieldValues, event?: React.BaseSyntheticEvent) => {
    return onSubmit(data, event, args);
  };

  const onFormError = (data: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => {
    return onError && onError(data, event, args);
  };

  useImperativeHandle(forwardRef, () => args, [args]);

  return (
    <FormProvider {...args}>
      <form onKeyDown={preventEnterSubmit ? preventFormSubmit : undefined} onSubmit={args.handleSubmit(onFormSubmit, onFormError)}>
        {children && children(args)}
      </form>
      {validateInitially && <TriggerFormInitially {...args} />}
    </FormProvider>
  );
}

const FormHookWrapper = React.forwardRef(FormHookWrapperInner) as <TFieldValues extends FieldValues = FieldValues, TContext = AnyValue>(
  props: FormHookWrapperProps<TFieldValues, TContext> & { ref?: React.ForwardedRef<FormHookWrapperRef<TFieldValues, TContext>> }
) => ReturnType<typeof FormHookWrapperInner>;

export default FormHookWrapper;
