import { Observable, from } from 'rxjs';
import { axios } from '@core/axios';
import { map } from 'rxjs/operators';
import { Profile } from '@model/profile';

import { MessageResponse } from '@model/response/@base-response/dipo-back-base-response';
import { DIPOBackBaseResponse } from '@model/response/@base-response/dipo-back-base-response';
import { ForgetPasswordRequest } from '@model/request/forget-password.request';

export class ProfileService {
  private static baseUrl = '/user';
  public static getProfile(): Observable<Profile> {
    const url = `${this.baseUrl}`;
    return from(axios.get<DIPOBackBaseResponse<Profile>>(url)).pipe(map(({ data }) => data.data[0]));
  }
  public static getChangePassword(jwt: string): Observable<boolean> {
    const url = `${this.baseUrl}/change-password`;
    return from(
      axios.get<DIPOBackBaseResponse<boolean>>(url, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    ).pipe(map(({ data }) => data.data[0]));
  }
  public static updatePassword(body: ForgetPasswordRequest): Observable<MessageResponse> {
    const url = `${this.baseUrl}/change-password`;
    return from(axios.put<MessageResponse>(url, body)).pipe(map((d) => d.data));
  }
}
