import { useLiveChatDispatch, useLiveChatState } from '@context/LiveChat.context';
import { useProfileState } from '@context/Profile.context';
import { TokenService } from '@core/services/token.service';
import React, { memo, useEffect } from 'react';

const LiveChat: React.FC = () => {
  const { profile } = useProfileState();
  const { initial } = useLiveChatState();
  const dispatch = useLiveChatDispatch();

  useEffect(() => {
    if (profile && !initial) {
      window.ZWIZ.initWebChat();
      dispatch({ type: 'SET_INITIAL', payload: true });
    }
  }, [dispatch, initial, profile]);

  useEffect(() => {
    const handleEventRequestAuthToken = () => {
      TokenService.getLiveChatToken().subscribe((token) => {
        window.ZWIZ.handleUpdateToken({ token: `Bearer ${token.token}` });
      });
    };

    if (initial) {
      window.addEventListener('eventRequestAuthToken', handleEventRequestAuthToken);
    }

    return () => {
      if (initial) {
        window.removeEventListener('eventRequestAuthToken', handleEventRequestAuthToken);
      }
    };
  }, [initial]);
  return null;
};

export default memo(LiveChat);
