import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { InputUpload as InputFile } from '@mis/sushi-tailwind-react';
import { useNotification } from '@helpers/@hooks/useNotification';

export type InputUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> & React.ComponentProps<typeof InputFile>;

const InputUpload = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  placeholder,
  acceptLabel,
  maxFileSizeNumber,
  ...props
}: InputUploadProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label', 'error']);
  const { control, setError } = useFormContext<TFieldValues>();
  const notification = useNotification();
  const onChange = (change: (...event: (File | null)[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file?.size && maxFileSizeNumber && file?.size > maxFileSizeNumber) {
      notification.error({ message: t('error:feedback.file_size_too_large') });
      change(null);
      return;
    }
    change(file);
  };
  const onError = (change: (...event: (File | null)[]) => void) => (e: { message: string }) => {
    change(null);
    setError(name, { type: 'manual', message: e.message });
  };
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <div ref={ref}>
            <InputFile
              {...field}
              {...props}
              acceptLabel={error ? <span className="text-red-500">{t(error?.message as string)}</span> : acceptLabel}
              onChange={onChange(field.onChange)}
              fileName={field.value?.name || ''}
              onError={onError(field.onChange)}
              id={name}
              error={error ? true : false}
              placeholder={placeholder || t('other.please_enter')}
              data-testid={name}
              placeholderClassName={field.value ? 'text-[#0064C5]' : undefined}
            />
          </div>
        );
      }}
    />
  );
};

export default InputUpload;
