import * as React from 'react';
import { ButtonProps, Button as Btn } from '@mis/sushi-tailwind-react';
import { LinkButton } from './link-button/LinkButton';
import { DeleteButton } from './delete-button/DeleteButton';
import { TransparentButton } from './transparent-button/TransparentButton';
import { PrimaryButton } from './primary-button/PrimaryButton';
import { GreenButton } from './green-button/GreenButton';
import { SecondaryButton } from './secondary-button/SecondaryButton';

interface OverideButtonInterface extends React.FC<ButtonProps> {
  Primary: typeof PrimaryButton;
  Secondary: typeof SecondaryButton;
  Green: typeof GreenButton;
  Transparent: typeof TransparentButton;
  Delete: typeof DeleteButton;
  Link: typeof LinkButton;
}

const Button: OverideButtonInterface = (props) => {
  return <Btn type="button" {...props} />;
};

Button.displayName = 'OverideButton';
Button.Primary = PrimaryButton;
Button.Secondary = SecondaryButton;
Button.Green = GreenButton;
Button.Transparent = TransparentButton;
Button.Delete = DeleteButton;
Button.Link = LinkButton;

export default Button;
