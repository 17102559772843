import $axios from 'axios';
import { finalize } from 'rxjs';
import { env } from './env';
import { TokenService } from './services/token.service';
import { URLStoreService } from './services/url-store.service';

let isRefreshing = false;
let failedQueue: { reject: (d: unknown) => void; resolve: (d: unknown) => void }[] = [];
const MAX_REQUESTS_COUNT = env.maxRequest;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const baseURL = env.apiURL;
const authenBaseURL = env.ssoURL;
const authAxios = $axios.create({ baseURL: authenBaseURL });
const axios = $axios.create({ baseURL });

axios.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      if (config.headers && !config.headers['Authorization']) {
        const accessToken = TokenService.getToken();
        if (accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }
      }
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  },
  (error) => {
    Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  async (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && originalRequest.url.includes('/refreshToken')) {
      localStorage.clear();
      URLStoreService.setRedirectURL(window.location.pathname);
      window.location.href = '/digitalipo';
    }
  }
);

axios.interceptors.response.use(
  async (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return response;
  },
  async (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    const originalRequest = error.config;
    console.log(error?.response?.data, originalRequest);

    if (error.response && error.response.status === 403) {
      window.location.href = '/digitalipo/unauthorized';
    }

    if (error.response && error.response.status === 444) {
      window.location.href = '/digitalipo/not-found';
    }

    if (error.response && error.response.status === 401 && originalRequest.url.includes('/refreshToken')) {
      TokenService.clearToken();
      TokenService.clearRefreshToken();
      window.location.href = '/digitalipo';
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        TokenService.refreshToken()
          .pipe(
            finalize(() => {
              isRefreshing = false;
            })
          )
          .subscribe({
            next: ({ data }) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
              processQueue(null, data.token);
              resolve(axios(originalRequest));
            },
            error: (err) => {
              processQueue(err, null);
              reject(err);
            },
          });
      });
    }

    if (error.response && error.response.status === 500 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve) => {
        setTimeout(() => {
          const token = TokenService.getToken();
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          processQueue(null, token);
          isRefreshing = false;
          resolve(axios(originalRequest));
        }, 2000);
      });
    }
    return Promise.reject(error.response);
  }
);

export { axios, authAxios };
