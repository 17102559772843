import { useProfileState } from '@context/Profile.context';
import { FeedbackService } from '@core/services/feedback.service';
import { useNotification } from '@helpers/@hooks/useNotification';
import useTrueFalse from '@helpers/@hooks/useTrueFalse';
import isFAMember from '@helpers/@utils/is-member';
import { Modal } from '@mis/sushi-tailwind-react';
import { FeedbackFormType } from '@model/form/feedback.form';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { catchError, firstValueFrom, tap } from 'rxjs';
import FeedbackButton from './FeedbackButton';
import FeedbackForm from './FeedbackForm';

const Feedback: React.FC = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const { profile } = useProfileState();
  const [visible, onModalVisibleTrigger] = useTrueFalse();

  const onSubmit: SubmitHandler<FeedbackFormType> = (values) => {
    return firstValueFrom(
      FeedbackService.createFeedback(values).pipe(
        tap(() => {
          notification.success({ message: t('translation:save_success') });
          onModalVisibleTrigger();
        }),
        catchError((e) => {
          notification.error({ message: t('translation:save_failed') });
          return e;
        })
      )
    );
  };

  if (profile && isFAMember(profile)) {
    return (
      <>
        {/* Style for bottom for overiding */}
        <div id="feedback" className="fixed right-[13px] z-[9999]" style={{ bottom: '74px' }}>
          <FeedbackButton className="h-14 w-14" data-testid="feedbackBtn" onClick={onModalVisibleTrigger} />
        </div>
        {visible && (
          <Modal hideFooter width="fit-content" onCancel={onModalVisibleTrigger} visible className="z-[999]">
            <FeedbackForm profile={profile} onSubmit={onSubmit} onCancel={onModalVisibleTrigger} />
          </Modal>
        )}
      </>
    );
  }
  return null;
};

export default Feedback;
