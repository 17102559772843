import React from 'react';
import classNames from 'classnames';
import { Radio } from '@mis/sushi-tailwind-react';
import { useTranslation } from 'react-i18next';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { TextDisplay } from '@components/text-display/TextDisplay';
import ErrorMessage from '@components/error-message/ErrorMessage';
import { RadioOption } from '@model/radio';

export type RadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Radio> & {
    items: RadioOption[];
    viewOnly?: boolean;
  };

const RadioGroup = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  items,
  viewOnly,
  onChange,
  ...props
}: RadioGroupProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control } = useFormContext<TFieldValues>();

  const onRadioChange = (onchange: (...event: AnyValue[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onchange(e);
    onChange && onChange(e);
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (viewOnly) {
          const displayValue = items.find((i) => i.value === field.value)?.label;
          return <TextDisplay name={name} value={displayValue} />;
        }
        return (
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              {items.map(({ label, value }) => {
                return (
                  <Radio
                    {...field}
                    {...props}
                    key={value}
                    onChange={onRadioChange(field.onChange)}
                    className={classNames({ 'border-danger-900': !!error })}
                    value={value}
                    data-testid={`${value}Radio`}
                    checked={String(field.value) === String(value)}
                  >
                    {label}
                  </Radio>
                );
              })}
            </div>
            <ErrorMessage status={error ? 'error' : undefined} message={t(error?.message as string)} className="text-xs text-danger-900" />
          </div>
        );
      }}
    />
  );
};

export default RadioGroup;
