import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues } from 'react-hook-form';
import RadioGroup, { RadioGroupProps } from '@components/form-hook-input/radio-group/RadioGroup';
import ComponentHorizontalWithLabel, {
  ComponentHorizontalWithLabelProps,
} from '@components/component-with-label/ComponentHorizontalWithLabel';

export interface RadioGroupFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends RadioGroupProps<TFieldValues, TName>,
    ComponentHorizontalWithLabelProps {
  border?: boolean;
}

const RadioGroupForm = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  tooltip,
  placeholder,
  tooltipWidth,
  optional,
  border,
  ...props
}: RadioGroupFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  return (
    <ComponentHorizontalWithLabel label={label} tooltip={tooltip} tooltipWidth={tooltipWidth} optional={optional}>
      <div className={`rounded ${border ? 'border' : ''} p-2`}>
        <RadioGroup placeholder={placeholder || t('please_select', { label })} {...props} />
      </div>
    </ComponentHorizontalWithLabel>
  );
};

export default RadioGroupForm;
