import React from 'react';
import { Global, css } from '@emotion/react';
import { GlobalStyles as BaseStyles } from 'twin.macro';
import SVGColor from './svg-color';
import './override-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';

const customStyles = css([
  {
    body: {
      backgroundColor: '#f3f3f3',
    },
  },
]);

const GlobalStyles = (): React.ReactElement => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
    <SVGColor />
  </>
);

export default GlobalStyles;
