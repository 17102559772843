import React, { useMemo } from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { Dropdown, Dropdown as SDropdown } from '@mis/sushi-tailwind-react';
import { TextDisplay } from '@components/text-display/TextDisplay';
import isFullEmptyObject from '@helpers/@utils/is-full-empty-object';

export type InputDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof SDropdown> & {
    viewOnly?: boolean;
    overModal?: boolean;
  };

const InputDropdown = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  placeholder,
  options = [],
  viewOnly,
  overModal,
  ...props
}: InputDropdownProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control } = useFormContext<TFieldValues>();

  const dropdownStyle = useMemo<React.CSSProperties | undefined>(() => {
    let style: React.CSSProperties = {};
    if (props.dropdownRender) {
      style = { ...style, ...props.dropdownStyle };
    }
    if (overModal) {
      style = { ...style, zIndex: 10000 };
    }
    return !isFullEmptyObject(style) ? style : undefined;
  }, [overModal, props.dropdownRender, props.dropdownStyle]);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (viewOnly) {
          return <TextDisplay name={name} value={options.find((i) => i.value === field.value)?.label || field.value} />;
        }
        return (
          <Dropdown
            {...field}
            {...props}
            options={options}
            placeholder={placeholder || t('other.please_select')}
            status={error ? 'error' : undefined}
            message={t(error?.message as string)}
            data-testid={field.name}
            dropdownStyle={dropdownStyle}
            showArrow={!props.disabled}
          />
        );
      }}
    />
  );
};

export default InputDropdown;
