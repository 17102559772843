import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues } from 'react-hook-form';
import InputString, { InputStringProps } from '@components/form-hook-input/input-string/InputString';
import ComponentHorizontalWithLabel, {
  ComponentHorizontalWithLabelProps,
} from '@components/component-with-label/ComponentHorizontalWithLabel';

export interface InputStringFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputStringProps<TFieldValues, TName>,
    ComponentHorizontalWithLabelProps {}

const InputStringForm = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  tooltip,
  optional,
  placeholder,
  className,
  ...props
}: InputStringFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  return (
    <ComponentHorizontalWithLabel className={className} label={label} tooltip={tooltip} optional={optional}>
      <InputString placeholder={placeholder || (t('please_enter', { label }) as string)} {...props} />
    </ComponentHorizontalWithLabel>
  );
};

export default InputStringForm;
