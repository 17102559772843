import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@mis/sushi-tailwind-react';

export type InputTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Textarea> & {
    viewOnly?: boolean;
  };

const InputTextarea = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  placeholder,
  viewOnly,
  ...props
}: InputTextareaProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Textarea
            {...field}
            {...props}
            readOnly={viewOnly}
            value={field.value === null ? '' : field.value}
            autoComplete="off"
            placeholder={placeholder || t('other.please_enter')}
            message={t(error?.message as string)}
            status={error ? 'error' : undefined}
            data-testid={field.name}
          />
        );
      }}
    />
  );
};

export default InputTextarea;
