import { UserRole } from '@model/enum/user-role.enum';

export const FA_MEMBER_ROLES = [
  UserRole.ROLE_MD,
  UserRole.ROLE_APPROVER,
  UserRole.ROLE_CREATOR,
  UserRole.ROLE_MD_APPROVER,
  UserRole.ROLE_UNDERWRITER,
  UserRole.ROLE_CREATOR_UNDERWRITER,
  UserRole.ROLE_APPROVER_UNDERWRITER,
];

export const SUPER_ADMIN_ROLES = [UserRole.ROLE_SUPER_ADMIN];
