import detectClassNames from '@helpers/@utils/detectClassNames';
import React, { ReactNode } from 'react';

interface LinkButtonProps {
  children: ReactNode;
  onClick: () => void;
  type: string;
  className?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ children, onClick, type, className }) => {
  return (
    <div data-testid={type} className={detectClassNames('w-max cursor-pointer text-sm', className)} onClick={onClick}>
      {children}
    </div>
  );
};
