import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues } from 'react-hook-form';
import InputDropdown, { InputDropdownProps } from '@components/form-hook-input/input-dropdown/InputDropdown';
import ComponentHorizontalWithLabel, {
  ComponentHorizontalWithLabelProps,
} from '@components/component-with-label/ComponentHorizontalWithLabel';

export interface InputDropdownFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputDropdownProps<TFieldValues, TName>,
    ComponentHorizontalWithLabelProps {}

const InputDropdownForm = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  tooltip,
  placeholder,
  tooltipWidth,
  ...props
}: InputDropdownFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  return (
    <ComponentHorizontalWithLabel label={label} tooltip={tooltip} tooltipWidth={tooltipWidth}>
      <InputDropdown placeholder={placeholder || t('please_select', { label })} {...props} />
    </ComponentHorizontalWithLabel>
  );
};

export default InputDropdownForm;
