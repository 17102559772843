import { Checkbox, Tooltip } from '@mis/sushi-tailwind-react';
import { FieldController } from '@model/field-controller';
import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type CheckboxInputTooltip = string | { selected: string; deselected: string };

export type CheckboxFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> &
  React.ComponentProps<typeof Checkbox> & {
    inputTooltip?: CheckboxInputTooltip;
  };

const CheckboxForm = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  inputTooltip,
  onChange,
  ...props
}: CheckboxFormProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control } = useFormContext<TFieldValues>();

  const onChangeValue = (o: (...event: AnyValue[]) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    o(e);
    onChange && onChange(e);
  };

  const getTootip = (tooltip: CheckboxInputTooltip | undefined, selected: boolean) => {
    if (typeof tooltip === 'string') {
      return tooltip;
    }
    if (typeof tooltip === 'object') {
      return selected ? tooltip.selected : tooltip.deselected;
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const tooltip = getTootip(inputTooltip, field.value);
        const renderCheckbox = (
          <Checkbox
            {...field}
            {...props}
            id={name}
            onChange={onChangeValue(field.onChange)}
            checked={field.value}
            value={field.value === null ? '' : field.value}
            message={t(error?.message as string)}
            status={error ? 'error' : undefined}
            data-testid={name}
          />
        );
        return tooltip ? (
          <Tooltip title={tooltip} placement="topLeft">
            {renderCheckbox}
          </Tooltip>
        ) : (
          renderCheckbox
        );
      }}
    />
  );
};

export default CheckboxForm;
