import React from 'react';
import { FilterRoutesFn, Outlet, ReactLocation, Router } from '@tanstack/react-location';
import routers from 'router.config';
import './App.css';
import { SushiTailwindThemeProvider, SushiTailwindThemeProviderProps } from '@mis/sushi-tailwind-react';
import { ProfileProvider } from '@context/Profile.context';
import { LayoutProvider } from '@context/Layout.context';
import PendingElement from '@components/pending-element/PendingElement';
import ErrorRouteFetch from '@components/error-route-fetch/ErrorRouteFetch';
import { ReactQueryProvider } from '@context/ReactQuery.context';
import { rankRoutes } from '@tanstack/react-location-rank-routes';
import Feedback from '@components/feedback/Feedback';
import ScrollToTop from '@components/scroll-to-top/ScrollToTop';
import './globals';
import { LiveChatProvider } from '@context/LiveChat.context';

const location = new ReactLocation();

interface NewSushiTailwindThemeProviderProps extends Omit<SushiTailwindThemeProviderProps, 'children'> {
  children: React.ReactNode;
}

function NewSushiTailwindThemeProvider({ children }: { children: React.ReactNode }) {
  const NewSushiTailwindThemeProvider: React.FC<NewSushiTailwindThemeProviderProps> = SushiTailwindThemeProvider;
  return <NewSushiTailwindThemeProvider>{children}</NewSushiTailwindThemeProvider>;
}

const App: React.FC = () => {
  return (
    <LayoutProvider collapsed={false}>
      <NewSushiTailwindThemeProvider>
        <ReactQueryProvider>
          <ProfileProvider profile={null} passwordExpired={null}>
            <LiveChatProvider initial={false}>
              <Router
                basepath="/digitalipo"
                location={location}
                filterRoutes={rankRoutes as FilterRoutesFn}
                routes={routers}
                defaultPendingElement={<PendingElement />}
                defaultErrorElement={<ErrorRouteFetch />}
                defaultPendingMs={100}
                defaultPendingMinMs={100}
              >
                <>
                  <Outlet />
                  <ScrollToTop />
                </>
              </Router>
              <Feedback />
            </LiveChatProvider>
          </ProfileProvider>
        </ReactQueryProvider>
      </NewSushiTailwindThemeProvider>{' '}
    </LayoutProvider>
  );
};

export default App;
