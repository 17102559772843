import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from '@assets/styles/global-styles';
import '@mis/sushi-tailwind-react/dist/sushi.css';
import 'dayjs/locale/en';
import 'dayjs/locale/th';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import * as buddhistEra from 'dayjs/plugin/buddhistEra';
import * as dayjs from 'dayjs';
import PendingElement from '@components/pending-element/PendingElement';

dayjs.extend(customParseFormat);
dayjs.extend(buddhistEra as unknown as dayjs.PluginFunc<unknown>);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.Fragment>
    <Suspense fallback={<PendingElement />}>
      <GlobalStyles />
      <App />
    </Suspense>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
