import React from 'react';
import { BreadcrumbRouteProps } from '@mis/sushi-tailwind-react';
import { DefaultGenerics, MakeGenerics, Navigate, Route } from '@tanstack/react-location';
import { SecurityType } from '@model/enum/security-type.enum';
import { Profile } from '@model/profile';
import getDefaultData, { DefaultData } from '@helpers/@utils/get-default-data';
import { ClassicDIPOService } from '@core/services/classic-dipo.service';
import isFAMember from '@helpers/@utils/is-member';

type Breadcrumb = BreadcrumbRouteProps & {
  next?: string | null;
  back?: string | null;
};

export type LocationGenerics = MakeGenerics<{
  Params: {
    symbolId: string;
    securityType: SecurityType;
  };
  LoaderData: { passwordExpired: boolean; profile: Profile };
}>;

export type ContactLocationGenerics = MakeGenerics<{
  Params: {
    contactType: string;
    contactId: string;
  };
}>;

const loader = {
  loader: async (): Promise<Partial<DefaultData>> => {
    return await getDefaultData();
  },
};

const routers: Route<DefaultGenerics>[] = [
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/',
    element: () => import('./pages/landing').then((mod) => <mod.default />),
  },
  {
    path: '/auth',
    element: () => import('./pages/auth').then((mod) => <mod.default />),
  },
  {
    path: '/logout',
    element: () => import('./pages/logout').then((mod) => <mod.default />),
  },
  {
    path: '/unauthorized',
    element: () => import('./pages/unauthorized').then((mod) => <mod.default />),
    ...loader,
  },
  {
    path: '/not-found',
    element: () => import('./pages/notFound').then((mod) => <mod.default />),
    ...loader,
  },
  {
    path: '/change-password',
    element: () => import('./pages/changePassword').then((mod) => <mod.default />),
    ...loader,
  },
  {
    path: 'dashboard',
    element: () => import('./pages/dashboard').then((mod) => <mod.default />),
    ...loader,
  },
  {
    path: 'reserved-symbol',
    element: () => import('./@components/permission/FAPermission').then((mod) => <mod.default />),
    children: [
      {
        path: '/create/:securityType',
        element: () => import('./pages/reservedSymbol/create').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: 'จองชื่อย่อและเพิ่มข้อมูลของบริษัท' }];
          },
        },
      },
      {
        path: '/edit/:securityType/:symbolId',
        element: () => import('./pages/reservedSymbol/edit').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }];
          },
        },
        children: [
          {
            path: '/',
            element: () => import('./pages/reservedSymbol/edit/index/index').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลทั่วไปและชื่อย่อ' }];
              },
            },
          },
          {
            path: '/assigned-team',
            element: () => import('./pages/reservedSymbol/edit/assignedTeam').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลผู้ดูแล' }];
              },
            },
          },
          {
            path: '/major-shareholders',
            element: () => import('./pages/reservedSymbol/edit/majorShareholder').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'รายงานผลการขาย' }, { label: 'รายงานข้อมูลผู้ถือหุ้นที่เปิดเผยตามเกณฑ์' }];
              },
            },
          },
        ],
      },
      {
        path: '/preview/:securityType/:symbolId',
        element: () => import('./pages/reservedSymbol/preview').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }];
          },
        },
        children: [
          {
            path: '/',
            element: () => import('./pages/reservedSymbol/preview/index/index').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลทั่วไปและชื่อย่อ' }];
              },
            },
          },
          {
            path: '/application/create',
            element: () => import('./pages/reservedSymbol/preview/application/create').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'สร้างแบบคำขอและ Filing' }];
              },
            },
          },
        ],
      },
    ],
    ...loader,
  },
  {
    path: 'contact',
    element: () => import('./pages/contact').then((mod) => <mod.default />),
    children: [
      {
        path: 'create/:contactType',
        element: () => import('./pages/contact/create').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (profile: Profile): Breadcrumb[] => {
            return [getContactDashboardLinkByRoles(profile), { label: 'contact.title' }, { label: 'contact.node.create.title' }];
          },
        },
      },
      {
        path: 'edit/:contactId/:contactType',
        element: () => import('./pages/contact/edit').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (profile: Profile): Breadcrumb[] => {
            return [getContactDashboardLinkByRoles(profile), { label: 'contact.title' }, { label: 'contact.node.edit.title' }];
          },
        },
      },
      {
        path: 'manage',
        element: () => import('./pages/contact/manage').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (profile: Profile): Breadcrumb[] => {
            return [getContactDashboardLinkByRoles(profile), { label: 'การจัดการข้อมูลผู้ติดต่อ' }];
          },
        },
        children: [
          {
            path: 'ceo',
            element: () => import('./pages/contact/manage/ceo').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.ceo.title' }];
              },
            },
          },
          {
            path: 'event',
            element: () => import('./pages/contact/manage/event').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.event.title' }];
              },
            },
          },
          {
            path: 'set-key-contact',
            element: () => import('./pages/contact/manage/set-key-contact').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.set-key-contact.title' }];
              },
            },
          },
        ],
      },
    ],
    ...loader,
  },
  {
    path: 'admin',
    element: () => import('./@components/permission/SuperAdminPermission').then((mod) => <mod.default />),
    children: [
      {
        path: 'report',
        children: [
          {
            path: 'contact',
            element: () => import('./pages/admin/report/contact').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [
                  { label: 'Home', href: ClassicDIPOService.generateURLHomepage() },
                  { label: 'admin.report.title' },
                  { label: 'admin.report.node.contact.title' },
                ];
              },
            },
          },
        ],
      },
    ],
    ...loader,
  },
  {
    path: 'redirect',
    children: [
      { path: 'major-shareholder/:appId', element: () => import('./pages/redirect/majorShareholder').then((mod) => <mod.default />) },
    ],
    ...loader,
  },
  {
    path: 'report',
    children: [
      { path: 'major-shareholder/:appId', element: () => import('./pages/report/majorShareholder/:appId').then((mod) => <mod.default />) },
    ],
    ...loader,
  },
];

// temporary
function getContactDashboardLinkByRoles(profile: Profile | null): Breadcrumb {
  const href = isFAMember(profile) ? '/dashboard' : ClassicDIPOService.generateURLHomepage();
  return {
    label: 'Home',
    href: href,
  };
}

export default routers;
