import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { FieldController } from '@model/field-controller';
import { useTranslation } from 'react-i18next';
import { Input } from '@mis/sushi-tailwind-react';
import { TextDisplay } from '@components/text-display/TextDisplay';

export type InputStringProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FieldController<TFieldValues, TName> & React.ComponentProps<typeof Input>;

const InputString = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  rules,
  placeholder,
  ...props
}: InputStringProps<TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation(['label']);
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (props.viewOnly) {
          return <TextDisplay name={field.name} value={field.value}></TextDisplay>;
        }
        return (
          <Input
            {...field}
            {...props}
            value={field.value === null ? '' : field.value}
            autoComplete="off"
            message={t(error?.message as string)}
            placeholder={placeholder || t('other.please_enter')}
            status={error ? 'error' : undefined}
            data-testid={name}
          />
        );
      }}
    />
  );
};

export default InputString;
