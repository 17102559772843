import { ProfileService } from '@core/services/profile.service';
import { TokenService } from '@core/services/token.service';
import { Profile } from '@model/profile';
import { firstValueFrom, forkJoin } from 'rxjs';

export interface DefaultData {
  profile: Profile;
  passwordExpired: AnyValue;
}

export default async function getDefaultData(): Promise<Partial<DefaultData>> {
  const jwt = TokenService.getToken();
  const data$ = forkJoin({
    profile: ProfileService.getProfile(),
    passwordExpired: ProfileService.getChangePassword(jwt as string),
  });
  return await firstValueFrom(data$);
}
